import errors from "@/util/errors";

export default {
  data: () => ({
    startTime: new Date(Date.now()).toISOString(),
    endTime: new Date(Date.now()).toISOString(),
    minDateTime: new Date(Date.now()).toISOString(),
    auctionType: "fixed",
    auctionTypes: [
      {
        text: "Fixed price",
        value: "fixed",
      },
      {
        text: "Live auction",
        value: "live",
      },
    ],
  }),
  computed: {
    startTimeComputed() {
      return Math.floor(Date.parse(this.startTime) / 1000);
    },
    endTimeComputed() {
      return Math.floor(Date.parse(this.endTime) / 1000);
    },
    isSimpleAuction() {
      return this.auctionType === "fixed";
    },
  },
  methods: {
    timeAuctionValidation() {
      if (this.startTimeComputed < Math.floor(Date.now() / 1000)) {
        this.setError(errors.START_TIME_LESS_THAN_CURRENT);
        return false;
      }
      if (this.endTimeComputed < Math.floor(Date.now() / 1000)) {
        this.setError(errors.END_TIME_LESS_THAN_CURRENT);
        return false;
      }
      if (this.endTimeComputed <= this.startTimeComputed) {
        this.setError(errors.END_TIME_LESS_THAN_START);
        return false;
      }
      return true;
    },
  },
};
