<template>
  <div class="fel">
    <input
      type="file"
      @change="uploadFile(true)"
      style="display: none"
      ref="fileInput"
      :accept="acceptType"
    />

    <div class="fel-label">Upload {{ subject }}</div>

    <div
      class="fel-file"
      v-if="showUploadForm"
      @click="openFileInput('fileInput')"
      @drop.prevent="
        $emit('dropped', $event);
        uploadFile(false);
      "
      @dragover="$emit('dragover', $event)"
    >
      <div class="svg">
        <svg><use xlink:href="#svg-upload" /></svg>
      </div>
      <div class="fel-title">Choose a&nbsp;file or&nbsp;drag it&nbsp;here.</div>
      <div class="fel-description" v-if="resourceType === 'Image'">
        ARW, AVIF, CR2, FLIF, HDP, JP2, JPE, JPEG, JPG, JXR, PNG, SVG, TGA, TIF,
        TIFF, WDP, WEBP. Max&nbsp;100MB.
      </div>
      <div class="fel-description" v-if="resourceType === 'Video'">
        3G2, 3GP, AVI, M2TS, M3U8, MKV, MOV, MP4, MPEG, MTS, MXF, OGV, TS, WEBM,
        WMV. Max&nbsp;100MB.
      </div>
      <div class="fel-description" v-if="resourceType === 'Gif'">
        GIF. Max&nbsp;100MB.
      </div>
      <div class="fel-description" v-if="resourceType === 'Audio'">
        AAC, AIFC, AMR, FLAC, M4A, MP3, OGG, OPUS. Max&nbsp;100MB.
      </div>
    </div>

    <div class="fel-file" v-if="showPreviewImage">
      <TokenContentType
        :file-url="fileUrl"
        :token-type="resourceType"
        :show-audio-player="showAudioPlayer"
        :preview-url="previewUrl"
      />
      <button type="button" class="round" @click="$emit('deleteUploadedFile')">
        Delete {{ subject }}
      </button>
    </div>

    <div class="fel-file" v-if="showLoadingIdentifier">
      <div class="loader">
        <VueEllipseProgress
          :progress="progress"
          :size="100"
          :thickness="3"
          emptyColor="transparent"
          color="#3f51b5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TokenContentType from "@/components/common/TokenContentType";
import openFileInputMixin from "@/mixins/openFileInputMixin";
export default {
  name: "UploadForm",
  components: { TokenContentType },
  mixins: [openFileInputMixin],
  props: {
    showAudioPlayer: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    subject: {
      required: true,
    },
    fileUrl: {
      required: true,
    },
    previewUrl: {
      required: true,
    },
    resourceType: {
      required: true,
    },
    acceptType: {
      required: true,
    },
    progress: {
      required: true,
    },
  },
  data: () => ({
    file: null,
  }),
  computed: {
    showUploadForm() {
      return !this.file && !this.fileUrl;
    },
    showLoadingIdentifier() {
      return !!(this.file && !this.fileUrl);
    },
    showPreviewImage() {
      return !!this.fileUrl;
    },
  },
  methods: {
    uploadFile(needEmit) {
      this.file = this.$refs.fileInput.files[0];
      needEmit && this.$emit("uploadFile");
    },
  },
  mounted() {
    this.file = this.$refs.fileInput.files[0] || null;
  },
};
</script>
