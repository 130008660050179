<template>
  <div>
    <CreateCollectionModal
      v-if="showCollectionModal"
      @success="collectionCreated"
      @close="showCollectionModal = false"
    />

    <div id="intro">
      <div class="section">
        <h1>Create NFT</h1>
      </div>
    </div>

    <div id="create-token" class="section">
      <form class="flex">
        <div class="half">
          <div class="fel">
            <div class="fel-label">Type of token</div>
            <div
              class="radio radio-buttons"
              :class="{ disabled: fileSelected }"
            >
              <label v-for="(item, index) in tokenTypes" :key="index">
                <input
                  type="radio"
                  name="token"
                  :value="item"
                  v-model="tokenType"
                  :disabled="item !== tokenType && (secureUrl || fileSelected)"
                  @change="nftMinting"
                />
                <span class="cell"></span>
                <span class="text">{{ item }}</span>
              </label>
            </div>
          </div>

          <UploadForm
            :preview-url="previewUrl"
            @dropped="dropped('UploadFile', $event)"
            @dragover="dragover"
            @deleteUploadedFile="deleteUploadedFile(false)"
            :file-url="secureUrl"
            :resource-type="tokenType"
            :progress="progress"
            :accept-type="acceptType"
            :subject="'file'"
            @uploadFile="uploadFile(false)"
            ref="UploadFile"
          />

          <UploadForm
            :show-audio-player="false"
            :preview-url="previewUrl"
            v-if="tokenType === 'Audio' && secureUrl"
            @dropped="dropped('UploadFilePreview')"
            @dragover="dragover"
            @deleteUploadedFile="deleteUploadedFile(true)"
            :file-url="previewUrl"
            :resource-type="'image'"
            :progress="progress"
            :accept-type="'image/*'"
            :subject="'cover'"
            @uploadFile="uploadFile(true)"
            ref="UploadFilePreview"
          />

          <div class="fel">
            <div class="switcher">
              <label>
                <input type="checkbox" value="lazy" v-model="isLazyMinting" />
                <span class="cell"></span>
                <span class="text">Free Minting Option (Lazy minting)</span>
              </label>
            </div>

            <div class="description">
              Lazy Minting option allows creating NFTs with no&nbsp;gas fees
              initially. Such fee would be&nbsp;requested from the buyer upon
              a&nbsp;first sale transaction.
            </div>
          </div>

          <div class="fel" v-if="!isLazyMinting">
            <div class="fel-label">Type of auction</div>
            <div class="radio radio-buttons">
              <label v-for="(item, index) in auctionTypes" :key="index">
                <input
                  type="radio"
                  name="auction"
                  :value="item.value"
                  v-model="auctionType"
                />
                <span class="cell"></span>
                <span class="text">{{ item.text }}</span>
              </label>
            </div>
          </div>

          <div class="fel" v-if="!isSimpleAuction">
            <div class="fel-label">Starting Date</div>
            <datetime
              v-model="startTime"
              :type="'datetime'"
              :min-datetime="minDateTime"
            />
          </div>

          <div class="fel" v-if="!isSimpleAuction">
            <div class="fel-label">Expiration Date</div>
            <datetime
              v-model="endTime"
              :type="'datetime'"
              :min-datetime="minDateTime"
            />
          </div>

          <div class="fel">
            <div class="fel-label">Name of token</div>
            <input
              type="text"
              name="name"
              v-model="titleComputed"
              @blur="nftMinting"
              id="title"
            />
            <div class="fel-error">{{ titleError }}</div>
          </div>

          <div class="fel">
            <div class="fel-label">Description</div>
            <textarea
              name="description"
              v-model="descriptionComputed"
              @blur="nftMinting"
              id="description"
            />
            <div class="fel-error">{{ descriptionError }}</div>
          </div>

          <div class="fel">
            <div class="fel-label">Collections</div>
            <div class="fel-collections">
              <div class="fel-item" @click="showCollectionModal = true">
                <div class="svg">
                  <svg><use xlink:href="#svg-add" /></svg>
                </div>
              </div>

              <PreCreateCollectionCard
                v-for="(item, index) in items"
                :key="index"
                :collection="item"
                :index="index"
                @click="selectCollection"
              />
            </div>
            <ScrollLoader
              :loader-method="getUserNftCollections"
              :loader-disable="disableLoading"
              :loader-distance="300"
              ><img width="200" height="200" src="@/assets/img/preloader.svg" />
            </ScrollLoader>
          </div>

          <div class="fel">
            <div class="fel-label">Royalty %</div>
            <input
              type="number"
              name="royalty"
              v-model="royaltyComputed"
              step="0.00001"
              @blur="nftMinting"
              id="royalty"
            />
            <div class="fel-error">{{ royaltyError }}</div>
          </div>

          <div class="fel">
            <div class="fel-label" v-if="isSimpleAuction">Price</div>
            <div class="fel-label" v-else>Minimum bid</div>

            <div class="fel-price">
              <input
                type="number"
                name="price"
                placeholder="0"
                step="0.00000001"
                v-model="formatPrice"
                @input="makePriceInput"
                @paste="makePriceInput"
                @blur="nftMinting"
                id="price"
              />
              <div class="coin">{{ getSymbol }}</div>
            </div>
            <div class="fel-error">{{ priceError }}</div>
            <div class="fel-eq">Equivalent {{ usdPrice }} USD</div>
          </div>

          <div class="fel">
            <div class="fel-label">Tags</div>
            <div class="fel-tags">
              <div class="fel-wrapper">
                <div class="flex">
                  <div class="tag" v-for="(item, index) in tags" :key="index">
                    <span>#{{ item }}</span>
                    <span class="svg" @click="deleteTag(index)">
                      <svg><use xlink:href="#svg-close" /></svg>
                    </span>
                  </div>
                </div>
              </div>
              <div class="fel-tools">
                <input
                  type="text"
                  placeholder="#"
                  v-model.trim="tag"
                  @keydown.space="(event) => event.preventDefault()"
                  @keydown.enter.prevent
                  @keyup.enter.prevent="addTag"
                />
                <div class="button round" @click="addTag">add</div>
              </div>
            </div>
          </div>

          <div class="buttons">
            <button
              type="button"
              class="green"
              :disabled="disableCreateBtn"
              @click="createToken"
            >
              Create NFT
            </button>
          </div>
        </div>

        <div class="half">
          <div class="fel-label">Preview</div>

          <TokenCard
            v-if="!startTimeSelected"
            :disable-control="true"
            :token="{
              activeAuction: {
                type: isSimpleAuction ? 'SIMPLE_AUCTION' : 'TIME_AUCTION',
                currency: getSymbol,
                startTime: startTimeComputed,
                endTime: endTimeComputed,
                minBidPrice: +this.formatPrice,
              },
              title: title,
              fileUrl: secureUrl,
              previewUrl: previewUrl,
              type: tokenType,
              owner: {
                id: getId,
                address: getAddress,
                nickname: getNickName,
              },
              currency: getSymbol,
              metaPrice: +this.formatPrice,
            }"
          />
        </div>
      </form>
    </div>
    <!--[ #create-token ]-->
  </div>
</template>

<script>
import http from "@/util/http-common";
import setProcessingMixin from "@/mixins/setProcessingMixin";
import api from "@/api/api";
import contract from "@/api/contract";
import capitalizeFirstLetterMixin from "@/mixins/capitalizeFirstLetterMixin";
import cloudinary from "@/util/cloudinary";
import auctionConfigMixin from "@/mixins/auctionConfigMixin";
import CreateCollectionModal from "@/components/modals/CreateCollectionModal";
import PreCreateCollectionCard from "@/components/common/PreCreateCollectionCard";
import checkIsWalletConectMixin from "@/mixins/checkIsWalletConectMixin";
import { mapGetters } from "vuex";
import formatNumberMixin from "@/mixins/formatNumberMixin";
import convertToUsdPriceMixin from "@/mixins/convertToUsdPriceMixin";
import TokenCard from "@/components/common/TokenCard";
import UploadForm from "@/components/common/UploadForm";
import errors from "@/util/errors";
import notices from "@/util/notices";
import successes from "@/util/successes";
import getUserNFTCollectionsMixin from "@/mixins/collection/getUserNFTCollectionsMixin";
import tokenInfoValidationMixin from "@/mixins/token/TokenInfoValidationMixin";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "TokenCreate",
  mixins: [
    setProcessingMixin,
    capitalizeFirstLetterMixin,
    auctionConfigMixin,
    checkIsWalletConectMixin,
    formatNumberMixin,
    convertToUsdPriceMixin,
    getUserNFTCollectionsMixin,
    tokenInfoValidationMixin,
    useLinkMixin,
  ],
  data: () => ({
    startTimeSelected: false,
    showCollectionModal: false,
    fileSelected: false,
    tokenTypes: ["Image", "Gif", "Video", "Audio"],
    tokenType: "Image",
    file: null,
    progress: 0,
    publicId: null,
    collectionIds: [],
    secureUrl: null,
    innerId: null,
    title: null,
    description: null,
    royalty: null,
    price: null,
    usdPrice: 0,
    isLazyMinting: false,

    previewId: null,
    previewUrl: null,

    royaltyError: null,
    priceError: null,
    maxCreatorPercent: 0,
    minCreatorPercent: 0,
    tags: [],
    tag: "",

    canCreateToken: true,
  }),
  computed: {
    ...mapGetters({
      getSymbol: "wallet/getSymbol",
      getAddress: "user/getAddress",
      getNickName: "user/getNickName",
      getId: "user/getId",
      getMethodsInitialized: "wallet/getMethodsInitialized",
      getForwarderContract: "wallet/getForwarderContract",
      getMarketContract: "wallet/getMarketContract",
    }),
    royaltyComputed: {
      get() {
        return this.royalty;
      },
      set(val) {
        this.royaltyError = null;
        if (!val) {
          this.royaltyError = this.$t(errors.REQUIRED_FIELD);
        }
        if (val < this.minCreatorPercent || val > this.maxCreatorPercent) {
          this.royaltyError = `${this.$t(errors.VALUE_RANGE_ERROR)} ${
            this.minCreatorPercent
          } - ${this.maxCreatorPercent.toFixed(5).toString()}`;
        }
        this.royalty = val;
      },
    },
    formatPrice: {
      get() {
        return this.price;
      },
      set(val) {
        this.priceError = null;
        if (!val) {
          this.priceError = this.$t(errors.REQUIRED_FIELD);
          this.price = null;
          return;
        }
        this.price = val;
      },
    },
    disableCreateBtn() {
      return (
        this.priceError ||
        this.royaltyError ||
        this.titleError ||
        this.descriptionError
      );
    },
    resourceType() {
      return this.tokenType.toLowerCase();
    },
    acceptType() {
      return this.resourceType === "gif" ? "image/*" : `${this.resourceType}/*`;
    },
  },
  watch: {
    getMethodsInitialized(val) {
      if (val) {
        this._royaltyPercent();
      }
    },
    startTime() {
      this.startTimeSelected = true;
      setTimeout(() => {
        this.startTimeSelected = false;
      }, 0);
    },
    getSymbol() {
      this.makePriceInput();
    },
  },
  methods: {
    async createLazyMintingRequest() {
      const createTokenAndAuctionABI = [
        "function createTokenAndSimpleAuctionLowGas(uint256 royaltyPercent, uint256 price,uint256 innerId)",
      ];
      const createTokenAndAuctionSelector = "createTokenAndSimpleAuctionLowGas";
      const createTokenAndAuctionData = contract.encodeFunctionCall(
        [
          contract.formatPrice(this.royalty, 10 ^ 5),
          contract.formatPrice(this.price),
          this.innerId,
        ],
        createTokenAndAuctionABI,
        createTokenAndAuctionSelector
      );
      const metaTransactionRequest = {
        from: this.getAddress,
        to: this.getMarketContract,
        value: 0,
        data: createTokenAndAuctionData,
      };
      const domain = contract.generateDomainData(
        "Forwarder",
        this.getForwarderContract
      );
      const msgParams = JSON.stringify({
        types: {
          ForwardRequest: [
            { name: "from", type: "address" },
            { name: "to", type: "address" },
            { name: "value", type: "uint256" },
            { name: "data", type: "bytes" },
          ],
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" },
          ],
        },
        domain,
        primaryType: "ForwardRequest",
        message: metaTransactionRequest,
      });

      let params = [this.getAddress, msgParams];
      const signature = await contract.signTypedDataV4(params);
      return {
        ...metaTransactionRequest,
        signature,
      };
    },
    async deleteTag(index) {
      try {
        await api.deleteTokenTag({
          tagName: this.tags[index],
        });
        this.tags.splice(index, 1);
      } catch (e) {
        this.setError(e.response.data.code);
      }
    },
    async addTag() {
      try {
        if (!this.tag || this.tags.includes(this.tag)) {
          this.tag = "";
          return;
        }
        this.tag = this.tag.replaceAll("#", "");
        await api.addTokenTag({
          tagName: this.tag,
        });
        this.tags.push(this.tag);
        this.tag = "";
      } catch (e) {
        this.setError(e.response.data.code);
      }
    },
    makePriceInput() {
      this.usdPrice = this.formatNumber(
        this.convertToUsdPrice(+this.formatPrice),
        true
      );
    },
    async selectCollection(val) {
      this.setLoading(true);
      if (this.collectionIds.includes(val.collectionId)) {
        try {
          await api.deleteCollectionFromTokenImage({
            collectionId: val.collectionId,
          });
          this.items[val.index].active = false;
          this.collectionIds = this.collectionIds.filter(
            (i) => i !== val.collectionId
          );
        } catch (e) {
          this.setError(e.response.data.code);
        }
      } else {
        try {
          await api.addCollectionToTokenImage({
            collectionId: val.collectionId,
          });
          this.collectionIds.push(val.collectionId);
          this.items[val.index].active = true;
        } catch (e) {
          this.setError(e.response.data.code);
        }
      }
      this.setLoading(false);
    },
    async validate() {
      if (!this.canCreateToken) {
        await this.nftMinting();
        return false;
      }
      if (!this.title) {
        this.titleError = this.$t(errors.REQUIRED_FIELD);
        const el = document.getElementById("title");
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return false;
      }
      if (!this.description) {
        this.descriptionError = this.$t(errors.REQUIRED_FIELD);
        const el = document.getElementById("description");
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return false;
      }
      if (!this.royalty) {
        this.royaltyError = this.$t(errors.REQUIRED_FIELD);
        const el = document.getElementById("royalty");
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return false;
      }
      if (!this.price) {
        this.priceError = this.$t(errors.REQUIRED_FIELD);
        const el = document.getElementById("price");
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return false;
      }
      if (!this.secureUrl) {
        this.setError(errors.NEED_FILE_UPLOAD);
        return false;
      }
      const gasPrice = await contract.getGasPrice();
      if (gasPrice >= this.price) {
        this.setNeutral(errors.GAS_PRICE_WARNING);
        return false;
      }
      return !(!this.isSimpleAuction && !this.timeAuctionValidation());
    },
    async createToken() {
      if (!(await this.validate())) {
        return;
      }
      try {
        this.setNotice(notices.CREATING_TOKEN);
        this.setLoading(true);
        let response = null;
        if (this.isLazyMinting) {
          await this.completeMetaTransaction();
          const signResult = await this.createLazyMintingRequest();
          await this.completeMetaTransactionNftMinting(signResult);
        } else {
          switch (this.auctionType) {
            case "fixed":
              response = await contract.createTokenAndSimpleAuction(
                this.price,
                this.royalty
              );
              break;
            case "live":
              response = await contract.createTokenAndTimeAuction(
                this.startTimeComputed,
                this.endTimeComputed,
                this.price,
                this.royalty
              );
              break;
            default:
              response = await contract.createToken(this.royalty);
          }
          await this.completeNftMinting();
        }
        await this.$router.push(this.artworksLink());
        if (this.checkIsWalletConnect(response)) {
          this.setSuccess(successes.TRANSACTION_REQUEST_SENT);
        } else {
          this.setSuccess(successes.NFT_MINTED);
        }
      } catch (e) {
        this.setError(e.message);
      } finally {
        this.setLoading(false);
        this.setNotice(null);
      }
    },
    async nftMinting() {
      try {
        await api.nftMinting({
          description: this.description,
          fileId: this.publicId,
          fileUrl: this.secureUrl,
          title: this.title,
          type: this.tokenType.toUpperCase(),
          previewId: this.previewId,
          previewUrl: this.previewUrl,
          tags: this.tags,
          price: this.price,
          ownerPercent: this.royalty,
        });
      } catch (e) {
        this.setError(e.response.data.code);
      }
    },
    async completeNftMinting() {
      try {
        await api.completeNftMinting();
      } catch (e) {
        this.setError(e.response.data.code);
      }
    },
    async completeMetaTransactionNftMinting(request) {
      try {
        await api.completeMetaTransactionNftMinting({
          query: {
            innerId: this.innerId,
          },
          body: request,
        });
      } catch (e) {
        this.setError(e.response.data.code);
      }
    },
    async completeMetaTransaction() {
      try {
        this.innerId = await api.completeMetaTransaction({
          currency: this.getSymbol,
        });
      } catch (e) {
        this.setError(e.response.data.code);
      }
    },
    async dropped(fileInput, $event) {
      $event.preventDefault();
      this.$refs[fileInput].$refs.fileInput.files = $event.dataTransfer.files;
      switch (fileInput) {
        case "UploadFile":
          await this.uploadFile(false);
          break;
        case "UploadFilePreview":
          await this.uploadFile(true);
          break;
      }
    },
    dragover(e) {
      e.preventDefault();
    },
    async deleteUploadedFile(isPreview) {
      try {
        this.setLoading(true);
        this.setNotice(notices.DELETING_FILE);
        if (isPreview) {
          await api.deletePreviewTokenFile();
          this.previewUrl = null;
          this.previewId = null;
        } else {
          await api.deleteTokenFile();
          this.secureUrl = null;
          if (this.tokenType === "Audio") {
            await api.deletePreviewTokenFile();
            this.previewUrl = null;
            this.previewId = null;
          }
        }
        await this.nftMinting();
        this.resetFileInput(isPreview);
      } catch (e) {
        this.setError(e.response.data.error.message);
      } finally {
        this.setLoading(false);
        this.setNotice(null);
      }
    },
    resetFileInput(isPreview) {
      if (isPreview) {
        this.$refs.UploadFilePreview.$refs.fileInput.value = "";
        this.$refs.UploadFilePreview.file = null;
      } else {
        this.$refs.UploadFile.$refs.fileInput.value = "";
        this.$refs.UploadFile.file = null;
        this.fileSelected = false;
      }
    },
    async uploadFile(isPreview) {
      try {
        if (!this.canCreateToken) {
          await this.nftMinting();
          this.resetFileInput();
          return;
        }
        let file = null;
        if (isPreview) {
          file = this.$refs.UploadFilePreview.$refs.fileInput.files[0];
        } else {
          this.fileSelected = true;
          file = this.$refs.UploadFile.$refs.fileInput.files[0];
          let fileType = file.type.split("/")[1];
          let tokenType =
            fileType.toLowerCase() === "gif" ? "gif" : file.type.split("/")[0];
          this.tokenType = this.capitalizeFirstLetter(tokenType);
          if (!this.tokenTypes.includes(this.tokenType)) {
            this.setError(errors.WRONG_FILE_TYPE);
            this.resetFileInput(isPreview);
            this.tokenType = "Image";
            return;
          }
        }
        const formData = new FormData();
        formData.append("upload_preset", cloudinary.preset);
        formData.append("file", file);
        formData.append("folder", `${isPreview ? "Preview" : this.tokenType}s`);
        const response = await http.post(
          cloudinary.getCloudinaryUploadUrl(
            isPreview
              ? "image"
              : this.resourceType === "gif"
              ? "image"
              : this.resourceType === "audio"
              ? "raw"
              : this.resourceType
          ),
          formData,
          {
            params: {
              isPublic: true,
            },
            onUploadProgress: (progressEvent) => {
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              );
            },
          }
        );
        if (isPreview) {
          this.previewUrl = response.data.secure_url;
          this.previewId = response.data.public_id;
        } else {
          this.secureUrl = response.data.secure_url;
          this.publicId = response.data.public_id;
        }
        await this.nftMinting();
      } catch (e) {
        this.setError(e.response.data.error.message);
        this.resetFileInput(isPreview);
      }
    },
    async getUploadedData() {
      try {
        const response = await api.getUploadedData();
        this.description = response.description;
        this.publicId = response.fileId;
        this.secureUrl = response.fileUrl;
        this.royalty = response.ownerPercent;
        this.title = response.title;
        this.tags = response.tags || [];
        this.tokenType = this.capitalizeFirstLetter(
          response.type.toLowerCase()
        );
        this.previewUrl = response.previewUrl;
        this.previewId = response.previewId;
      } catch (e) {
        this.previewUrl = null;
        this.previewId = null;
        setTimeout(() => {
          const code = e.response.data.code?.toString();
          this.setError(code);
          if (code === "2008") {
            this.canCreateToken = false;
          }
        }, 1000);
      }
    },
    collectionCreated() {
      this.page = 0;
      this.disableLoading = false;
      this.items = [];
    },
    async _royaltyPercent() {
      try {
        this.maxCreatorPercent = await contract._royaltyPercent();
      } catch {
        this.maxCreatorPercent = 0;
      }
    },
    async init() {
      this.setLoading(true);
      await this.getUploadedData();
      if (this.getMethodsInitialized) {
        await this._royaltyPercent();
      }
      this.setLoading(false);
    },
  },
  async mounted() {
    await this.init();
  },
  components: {
    UploadForm,
    TokenCard,
    PreCreateCollectionCard,
    CreateCollectionModal,
  },
};
</script>
